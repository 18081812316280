import React, { useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box, Badge, PseudoBox, Button } from "@chakra-ui/core"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1 } from "../components/Typography"
import firebase from "gatsby-plugin-firebase"

export function NoticeCard({ notice, ...rest }) {
  const sources = notice.mobileImage
    ? [
        notice.mobileImage?.childImageSharp.fixed,
        {
          ...notice.desktopImage?.childImageSharp.fixed,
          media: `(min-width: 768px)`,
        },
      ]
    : null
  return (
    <PseudoBox
      w="100%"
      m={2}
      _hover={{
        boxShadow: "xl",
      }}
    >
      <Link to={`/${notice.fields.slug}`}>
        <Flex
          borderWidth="1px"
          boxShadow="sm"
          rounded="lg"
          overflow="hidden"
          flex="0 0 100%"
          minW={["100px", "350px"]}
          w="100%"
          minH="75px"
          direction="row"
          justify="center"
          align="center"
          {...rest}
        >
          {sources ? <Img fixed={sources} alt="featuredImage" /> : null}
          <Flex
            paddingX={4}
            paddingY={2}
            flex={1}
            direction="column"
            minH="150px"
            justify="space-between"
            align="flex-start"
          >
            <Flex align="baseline" direction={["column", "row"]}>
              <Badge rounded="full" px="2" variantColor="blue">
                {notice.noticeCategories?.name || null}
              </Badge>
              <Flex direction="column">
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  textTransform="uppercase"
                  ml="2"
                >
                  {notice.fields.date}
                </Box>
              </Flex>
            </Flex>
            <Box
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              textAlign="left"
            >
              {notice.title}
            </Box>
            <Button variant="ghost" variantColor="Gray" color="Gray" w="100%">
              View
            </Button>
          </Flex>
        </Flex>
      </Link>
    </PseudoBox>
  )
}

export default function NoticeListTemplate({ data, pageContext, ...rest }) {
  const notices = data.allNotice.edges || []
  const { currentPage, numPages } = pageContext || {}
  const hasMore = numPages > currentPage
  const hasLess = currentPage > 1
  useEffect(() => {
    firebase.analytics().setCurrentScreen(`notice_page_${currentPage}`)
  }, [])

  return (
    <Layout>
      <SEO title="Notices" />
      <H1 textAlign="center">Community Notices</H1>
      <Flex
        justify="flex-start"
        align="center"
        wrap="wrap"
        flex={1}
        w="100%"
        direction="row"
      >
        {notices.map(notice => (
          <NoticeCard notice={notice.node} key={notice.node.id} />
        ))}
      </Flex>
      <Flex justify="center" align="center" w="100%" mt={8}>
        <Button
          isDisabled={!hasLess}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage > 2) {
              navigate(`/notices/${currentPage - 1}`)
            } else navigate(`/notices`)
          }}
        >
          Previous
        </Button>
        <Flex marginX={4}>
          Page {currentPage} of {numPages}
        </Flex>
        <Button
          isDisabled={!hasMore}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage < numPages) {
              navigate(`/notices/${currentPage + 1}`)
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query noticeList($skip: Int!, $limit: Int!) {
    allNotice(
      skip: $skip
      limit: $limit
      sort: { fields: fields___date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          noticeCategories {
            name
          }
          desktopImage: image {
            childImageSharp {
              fixed(width: 200, height: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          mobileImage: image {
            childImageSharp {
              fixed(width: 150, height: 160) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          description
          fields {
            date(formatString: "DD MMMM YYYY")
            slug
          }
        }
      }
    }
  }
`
